import { Link } from "gatsby";
import React from "react";

import * as pageStyles from "../global-styles/page.module.css";

export const NextPageLink = ({ to, title }: { to: string; title: string }) => {
  return (
    <>
      <div>Next Page:</div>
      <h2 className="title is-5">
        <Link title={title} to={to} className={pageStyles.nextPageLink}>
          {title}
        </Link>
      </h2>
    </>
  );
};
