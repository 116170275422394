import { PageProps } from "gatsby";
import React from "react";

import { Clefs } from "../../components/pages/Clefs";

import { dnProps } from "../../js/utils";

const ClefsPage = (props: PageProps) => <Clefs {...{ ...dnProps, ...props }} />;

export default ClefsPage;
