import React from "react";
import { Link } from "gatsby";
import Layout from "../parts/Layout";
import { NextPageLink } from "../parts/NextPageLink";
import { PageComponentProps } from "../../types/types";

// @ts-ignore
import ClefsDn from "../../images/svg-dn/Clairnote-clefs.svg";
// @ts-ignore
import ClefsSn from "../../images/svg-sn/Clairnote-clefs.svg";

// @ts-ignore
import ClefsTransposedDn from "../../images/svg-dn/Clairnote-clefs-transposed.svg";
// @ts-ignore
import ClefsTransposedSn from "../../images/svg-sn/Clairnote-clefs-transposed.svg";

// @ts-ignore
import ClefsAllTraditionalDn from "../../images/svg-dn/Clairnote-clefs-all-traditional.svg";
// @ts-ignore
import ClefsAllTraditionalSn from "../../images/svg-sn/Clairnote-clefs-all-traditional.svg";

import * as pageStyles from "../global-styles/page.module.css";

export const Clefs = (props: PageComponentProps) => {
  const { clairnoteName, urlDir, dn } = props;
  const title = "Clefs";
  const description = `Discussion and illustration of clefs in ${clairnoteName} music notation, and the symbols that correspond to traditional treble, bass, and alto clef symbols.`;
  return (
    <Layout title={title} description={description} {...props}>
      <article className="page">
        <h1 className={`title is-3 ${pageStyles.pageTitle}`}>{title}</h1>
        <div>
          <p>
            {clairnoteName}'s clefs simply indicate the <em>octave register</em>{" "}
            of the current staff. Unlike traditional clef signs, they do not
            change the notes that are represented by the lines and spaces of the
            staff. In {clairnoteName} the lines and spaces always represent the
            same notes in each octave (see{" "}
            <Link to={urlDir + "staff/"} title="Staff">
              Staff
            </Link>
            ). This simplification makes learning to read music easier. Piano
            players really benefit because in the traditional system they have
            to read music in both treble and bass clefs at the same time.
          </p>
          <p>
            Here is the same note ("middle C" or "C4") shown in treble, bass,
            and alto clefs in both {clairnoteName} and traditional notation. The
            resemblance between traditional clefs and {clairnoteName} clefs
            makes it easier to learn to read both systems.
          </p>
          <div className={pageStyles.imageContainer}>
            <img
              src={dn ? ClefsDn : ClefsSn}
              alt={"Clefs in " + clairnoteName + " music notation"}
            />
          </div>
          <p>
            The numbers in the {clairnoteName} clefs indicate the octave of the
            pair of staff lines nearest to the number. They follow the common
            numbering scheme for octaves. (
            <a
              href="https://en.wikipedia.org/wiki/Scientific_pitch_notation"
              target="_blank"
              rel="external noreferrer noopener"
            >
              Scientific pitch notation
            </a>{" "}
            where an octave starts on C and extends up to B.) A higher or lower
            number in a clef indicates a higher or lower range/register on the
            staff. This is similar to transposed clefs in traditional music
            notation (also known as{" "}
            <a
              href="http://en.wikipedia.org/wiki/Clef#Octave_clefs"
              target="_blank"
              rel="external noreferrer noopener"
            >
              octave clefs
            </a>
            ), but it is easier to see at a glance which octave(s) the clefs
            signify.
          </p>
          <p>
            Here is a series of clefs from low to high ranges, showing the note
            E in seven different octaves for both {clairnoteName} clefs and
            traditional transposed clefs.
          </p>
          <div className={pageStyles.imageContainer}>
            <img
              src={dn ? ClefsTransposedDn : ClefsTransposedSn}
              alt={"Transposed clefs in " + clairnoteName + " music notation"}
            />
          </div>

          <h2 className="title is-5">A Simpler Clef System</h2>
          <p>
            {clairnoteName}'s clef system simplifies the various different kinds
            of clefs found in traditional notation. When converting music from
            traditional notation to {clairnoteName}, traditional clefs become
            the {clairnoteName} clef that has the closest range (treble, bass,
            or alto, at a given octave register). This simplification is
            possible because {clairnoteName}'s clefs only indicate the octave
            register of the staff and do not change the notes on the staff lines
            and spaces.
          </p>
          <p>
            The following image shows the clefs on the {clairnoteName} staff
            that correspond to all of the different clefs on the traditional
            staff (french, treble, soprano, mezzosoprano, alto, tenor, baritone,
            varbaritone, bass, subbass, and percussion).
          </p>
          <div className={pageStyles.imageContainer}>
            <img
              src={dn ? ClefsAllTraditionalDn : ClefsAllTraditionalSn}
              alt={
                "Illustration of all clefs in traditional and " +
                clairnoteName +
                " music notation"
              }
            />
          </div>

          <p>
            In the traditional system all of these different clefs allow for
            fine-tuning of the range of the staff for a particular instrument or
            voice range. However, this specialization makes it harder for
            musicians to read music that is written in different clefs. Is this
            trade-off worth it? Most of the traditional clefs shown above are
            now rarely used. That suggests that the added complexity they
            introduce may not be (or may no longer be) worth the benefits they
            offer.
          </p>
          <p>
            Technically it would be possible to use the traditional treble,
            bass, and alto clef symbols with {clairnoteName} (e.g. for their
            aesthetics). That is, as long as it was understood that they only
            represent octave registers and do not affect the notes represented
            by the lines and spaces of the staff. However, this could easily
            cause confusion, so it is best to use {clairnoteName}'s clef
            symbols, for consistency and to keep things clear and unambiguous.
          </p>

          <NextPageLink
            to={urlDir + "key-signatures/"}
            title="Key Signatures"
          />
        </div>
      </article>
    </Layout>
  );
};
